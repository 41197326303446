// Gameplay.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocket } from '../providers/SocketContext';
import { useGame, useGameDispatch } from '../providers/GameContext';
import { Game, GameState } from '../constants/constants';
import { usePlayerName } from '../providers/PlayerNameContext';
import { useAudioPlayer } from 'react-use-audio-player';
import QRCode from "react-qr-code";

function Gameplay() {
    const playerName = usePlayerName()
    const isViewer = !playerName
    const navigate = useNavigate();

    const socket = useSocket();
    const { gameName } = useParams();
    const game = useGame()
    const gameDispatch = useGameDispatch()
    const { load: loadCountdown, play: playCountdown, stop: stopCountdown } = useAudioPlayer();
    const [interestingFact, setInterestingFact] = useState<string | null>(null);

    const [sentence, setSentence] = useState<string | null>(null);
    const [sentenceSubmitted, setSentenceSubmitted] = useState<boolean>(false)

    useEffect(() => {
        loadCountdown(require('../audio/Countdown.mp3'), { autoplay: false, 'loop': false });
    }, [])

    useEffect(() => {
        // This is where you'd initialize the game, fetch game state, or listen to game state updates.
        if (!socket) return

        console.log(game.state, socket)

        socket.on('guessingTimeRemaining', (remainingTime: number, totalTime: number) => {
            if (remainingTime === 10) {
                playCountdown()
            }
            gameDispatch({ type: 'GUESS_TIME', payload: { remainingTime, totalTime } });
        })

        socket.on('gameStateChange', (game: Game) => {
            // console.log('gameStateChange', game)
            gameDispatch({ type: 'LOADED_GAME', payload: game });
            if (game.state !== GameState.GUESSING) {
                stopCountdown()
            }
        })
        socket.on('updatePlayers', (game: Game) => {
            // console.log('game', game)
            gameDispatch({ type: 'LOADED_GAME', payload: game });
        });

        socket.on('newRound', () => {
            setSentenceSubmitted(false)
            setSentence(null)
        })

        socket.on('connect', () => {
            console.log("connected");
        })
    }, [socket]);

    useEffect(() => {
        console.log(game, gameName, socket)
        if (!gameName) {
            console.log('redirecting to game selection')
            navigate("/game-selection")
            return
        }
        if (!socket || !game) {
            return
        }
        socket.emit('joinGame', gameName)
    }, [gameName])

    useEffect(() => {
        let interval: string | number | NodeJS.Timer | undefined
        console.log('game.interestingFacts', game.interestingFacts)
        if (game.interestingFacts && game.interestingFacts.length > 0) {
            // Cycle through the interesting facts every 10 seconds
            let index = 0;

            const nextFact = (factIndex: number, end: boolean) => {
                if (game.interestingFacts && game.interestingFacts.length > 0 && !end) {
                    index = (factIndex + 1) % (game?.interestingFacts?.length || 1);
                    const fact = game.interestingFacts[index]

                    setInterestingFact(fact)
                    interval = setTimeout(() => nextFact(index, false), Math.round(0.85 * fact.split(' ').length * 1000))
                    return index
                }
                else {
                    return -1
                }
            }

            nextFact(index, false)
        }

        return function cleanup() {
            if (interval) {
                clearTimeout(interval);
            }
        };

    }, [game.interestingFacts])

    if (!socket) { return null }

    const submitGuess = (guess: string) => {
        // Logic to submit guess to the server or game engine
        console.log(`Guess submitted: ${guess}`);

        socket.emit('submitGuess', gameName, guess)
        // Update the game state accordingly, based on server response or game engine outcome.
    };

    const submitSentence = () => {
        if (!sentence) return

        socket.emit("submitSentence", gameName, sentence.trim());
        gameDispatch({ type: 'UPDATE_SENTENCE', payload: sentence.trim() });
        setSentenceSubmitted(true)
    };

    const startNewRound = () => {
        socket.emit("startNewRound", gameName);
        setInterestingFact(null)
        console.log("Starting new round", game);
    };



    return (
        <div className="gameplay-container">
            {game.state === GameState.LOBBY &&
                <>
                    {!isViewer && !sentenceSubmitted &&
                        <div>
                            <form style={{ position: "relative" }} onSubmit={submitSentence}>
                                <textarea
                                    className="textfield__container"
                                    value={sentence ?? ''}
                                    onChange={(e) => setSentence(e.target.value)}
                                    placeholder="Enter sentence"
                                    required
                                    rows={4}
                                />
                                <button className="p-2 bg-lime-600 button__submit" type="submit">Submit</button>
                            </form>
                        </div>
                    }
                    {!isViewer && sentenceSubmitted &&
                        <div>
                            <h2>Waiting for other players to submit their sentences</h2>
                        </div>
                    }

                    {isViewer && <div>
                        <h2>Waiting for players to submit their sentences</h2>
                        {game.players && game.players.length > 0 && game.players.filter(player => player.playerName && (!player.words || player.words.length <= 0) && player.connected).map((player) => (
                            player.playerName
                        )).join(', ')}
                        {game.players && game.players.length > 0 && game.players.filter(player => player.playerName && !!player.words && player.connected).map((player) => (
                            <div key={player.playerName}>
                                <div>{player.words.join(', ')}</div>
                            </div>
                        ))}
                    </div>}
                </>
            }

            {game.state === GameState.GENERATING_IMAGE && (
                <>
                    <div className='loader'>
                        <h2 className="loader__text">LOADING</h2>
                        <div className="loader__background"></div>
                    </div>

                    <div className='game__facts flex flex-column justify-center flex-wrap'>
                        {interestingFact && <p className="game__facts--item">{interestingFact}</p>}
                    </div>
                </>
            )}


            {game.state === GameState.GUESSING && (
                <div className='game__guessing'>
                    <h2 className="game__guessing--time text-center">{game.roundTime}</h2>
                    {game.image && <div className="image__container"><div className='image__border'></div><img className="image" src={game.image} alt="Game" /></div>}
                    {game.words.length > 0 && game.words.map((word) => {
                        const player = (game.players ?? []).find(player => player.playerName === playerName)
                        const guessedWord = (player?.guesses ?? []).map(word => word).includes(word) ? 'bg-lime-200' : 'bg-lime-600';
                        return (<button className={`p-2 m-1 ${guessedWord}`} key={word} onClick={() => {
                            !isViewer && submitGuess(word)
                        }}>{word}</button>)
                    })}
                </div>
            )}

            {game.state === GameState.ROUND_OVER &&
                <div className="p-2 md:p-10" style={{ "background": "#95edffe0", width: "100%" }}>
                    <div className="md:w-1/2 m-auto">
                        <div className="mb-4 flex items-center justify-center"><h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Game Over</h5></div>
                        {!isViewer && <div className="mb-4 flex items-center justify-end"><button className="bg-lime-600 p-2 button__play-again" onClick={startNewRound}>Play Again</button></div>}
                        <div className="flow-root">
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="flex font-normal justify-between py-3 sm:py-4">
                                    {game.players && game.players.length > 0 && game.usedWords.map((word, index) => (
                                        <div key={index}>
                                            <div className="font-bold">{word}</div>
                                            {game.players.map((player) => { if (!player.guesses.includes(word)) { return <></> } return (<div key={player.playerName}>{player.playerName}</div>) })}
                                        </div>
                                    ))}
                                </li>
                            </ul>
                            <div>
                                {game.players && game.players.length > 0 && [...game.players].sort((playerA, playerB) => playerB.score - playerA.score).map((player, index) => (
                                    <div key={index} className={`flex justify-between p-4 py-4 even:bg-blue-50 odd:bg-white bg-opacity-25 ${player.connected ? 'blur-none' : 'blur-sm'}`}>
                                        <div>{player.playerName?.toUpperCase()}: +{player.roundScore}pts</div>
                                        <div>{player.score}pts</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='game__join-code'>
                <QRCode value={window.location.href} />
            </div>
        </div>
    );
}

export default Gameplay;
