// GameSelection.tsx
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { usePlayerName } from '../providers/PlayerNameContext';
import { useGameDispatch } from '../providers/GameContext';
import { useSocket } from '../providers/SocketContext';

const fetchGames = () => {
    // Mockup function for fetching game data. Replace with actual API call.
    return Promise.resolve(['Game 1', 'Game 2', 'Game 3']); // Game identifiers
};

function GameSelection() {
    const socket = useSocket();
    const playerName = usePlayerName()
    const gameDispatch = useGameDispatch()
    const [games, setGames] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!socket) { return }
        socket.on('gameCreated', (games: string[]) => {
            setGames(games)
        })
    }, [socket]);

    if (!socket) { return null }

    const joinGame = (gameName: string) => {
        // Here you can do any setup needed to join a game, then navigate to the gameplay
        gameDispatch({ type: 'CREATE', payload: gameName });
        socket.emit('joinGame', gameName, playerName);
        console.log(`Joining ${gameName}...`);
        navigate(`/gameplay/${gameName}`); // Assuming your game code or ID would be part of the URL or state in a real app
    };

    const newGame = () => {
        socket.emit('createGame')
    }


    return (
        <div className="container mx-auto">
            <h1>Available Games {playerName}</h1>
            <button onClick={newGame}>New Game</button>
            <ul>
                {games.map((gameName, index) => (
                    <li key={index}>
                        {gameName} <button onClick={() => joinGame(gameName)}>Join</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default GameSelection;
