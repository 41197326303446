import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useSocket } from './SocketContext';

// Assuming dispatch's type is something like this, adjust according to your actual reducer
type Dispatch = (action: PlayerNameAction) => void; // Replace YourActionType with your actual action type

// Here you explicitly define the type for the context's value
export const PlayerNameContext = createContext<string | null>(localStorage.getItem('playerName'));

// And also for the dispatch context
export const PlayerNameDispatchContext = createContext<Dispatch>(() => { });


export function usePlayerName() {
    return useContext(PlayerNameContext);
}

export function usePlayerNameDispatch() {
    return useContext(PlayerNameDispatchContext);
}

interface PlayerNameProviderProps {
    children: ReactNode; // Define children prop type here
}

type PlayerNameAction =
    | { type: 'SET_NAME'; payload: string }
    | { type: 'CLEAR_NAME' };

export function PlayerNameProvider({ children }: PlayerNameProviderProps) {
    const socket = useSocket()
    const [playerName, dispatch] = useImmerReducer<string, PlayerNameAction>(
        playerNameReducer, localStorage.getItem('playerName') || ''
    );

    useEffect(() => {
        if (!socket || !playerName) {
            return
        };
        socket.emit('updatePlayerName', playerName)
        localStorage.setItem('playerName', playerName)
        // window.location.href = '/game-selection'
    }, [socket, playerName])

    return (
        <PlayerNameContext.Provider value={playerName}>
            <PlayerNameDispatchContext.Provider value={dispatch}>
                {children}
            </PlayerNameDispatchContext.Provider>
        </PlayerNameContext.Provider>
    );
}



// Your reducer function now uses the defined action type
function playerNameReducer(draft: string, action: PlayerNameAction) {
    switch (action.type) {
        case 'SET_NAME':
            return action.payload; // You might need to adjust this line to match your logic with immer
        case 'CLEAR_NAME':
            return ''; // Adjust based on your logic
        default:
            return draft; // Or throw an error for unknown action types
    }
}