import { ReactNode, createContext, useContext } from 'react';
import { Socket } from 'socket.io-client';

export const SocketContext = createContext<Socket | null>(null);

export function useSocket() {
    return useContext(SocketContext);
}

interface SocketProviderProps {
    children: ReactNode; // Define children prop type here
    socket: Socket
}
export function SocketProvider({ children, socket }: SocketProviderProps) {
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
}