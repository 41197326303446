import { createContext, useContext, useState } from "react";
import { Game, GameState } from "../constants/constants";
import { useSocket } from "./SocketContext";
import { useImmerReducer } from "use-immer";

const initialGame = {
    gameId: '',
    players: [],
    words: [],
    usedWords: [],
    round: 0,
    state: GameState.NOT_CONNECTED,
    image: null,
    roundTime: 0,
    interestingFacts: [],
    minimumWords: 3
} as Game

type GameAction =
    | { type: 'CREATE'; payload: string }
    | { type: 'UPDATE_SENTENCE'; payload: string }
    | { type: 'LOADED_GAME'; payload: Game }
    | { type: 'RESET_GAME' }
    | { type: 'GUESS_TIME'; payload: { remainingTime: number, totalTime: number } }
    | { type: 'CLEAR_NAME' };
type Dispatch = (action: GameAction) => void;


export const GameContext = createContext<Game>(initialGame);
export const GameDispatchContext = createContext<Dispatch>(() => { });

export function useGame() {
    return useContext(GameContext);
}

export function useGameDispatch() {
    return useContext(GameDispatchContext);
}


interface GameProviderProps {
    children: React.ReactNode; // Define children prop type here
}

export function GameProvider({ children }: GameProviderProps) {
    const socket = useSocket()


    const [game, dispatch] = useImmerReducer<Game, GameAction>(
        gameReducer, initialGame
    );

    return (
        <GameContext.Provider value={game} >
            <GameDispatchContext.Provider value={dispatch} >
                {children}
            </GameDispatchContext.Provider>
        </GameContext.Provider>
    )
}

// Your reducer function now uses the defined action type
function gameReducer(draft: Game, action: GameAction) {
    switch (action.type) {
        case 'CREATE':
            draft.gameId = action.payload;
            return draft; // You might need to adjust this line to match your logic with immer
        case 'LOADED_GAME':
            return action.payload;
        case 'RESET_GAME':
            return initialGame;
        case 'GUESS_TIME':
            draft.roundTime = action.payload.remainingTime;
            return draft
        default:
            return draft; // Or throw an error for unknown action types
    }
}