import React, { ReactNode } from 'react';

interface TemplateProps {
    children: ReactNode;
}

const Template: React.FC<TemplateProps> = ({ children }) => {
    return (
        <div className="bg-emerald-300 font-sans min-h-full flex justify-between flex-col">
            <div className="wood_panel__top"></div>
            <div className="game__background grow bg-center">
                <div className="container mx-auto p-2 md:p-4 grow">
                    {children}
                </div>
            </div>
            <div className="wood_panel__bottom"></div>
        </div>
    );
};

export default Template;
