import React, { useEffect } from "react";
import io from "socket.io-client";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAudioPlayer, useGlobalAudioPlayer } from 'react-use-audio-player';

import NameEntry from "./components/NameEntry";
import GameSelection from "./components/GameSelection";
import Gameplay from "./components/Gameplay";
import Dashboard from "./components/Dashboard";

import './App.scss'
import Template from "./components/Template";
import { PlayerNameProvider, usePlayerName } from "./providers/PlayerNameContext";
import { SocketProvider } from "./providers/SocketContext";
import { GameProvider } from "./providers/GameContext";

const apiBaseUrl = process.env.API_BASE_URL || "https://api.sparksplex.com";

const socket = io(apiBaseUrl, {
  auth: (cb) => cb(localStorage.getItem('playerName') ? { playerName: localStorage.getItem('playerName') } : {}),
  reconnection: true, // defaults to true
  reconnectionDelay: 10000, // defaults to 1000
  reconnectionDelayMax: 10000 // defaults to 5000
});

const App = () => {
  const playerName = usePlayerName()
  const { setVolume } = useGlobalAudioPlayer();
  const { load: loadBackground1 } = useAudioPlayer();
  const { load: loadBackground2 } = useAudioPlayer();

  useEffect(() => {
    loadBackground1(require('./audio/BackgroundAmbience.mp3'), { initialVolume: .1, loop: true, autoplay: true });
    loadBackground2(require('./audio/BackgroundAmbience2.mp3'), { initialVolume: .5, loop: true, autoplay: true });
  }, []);

  useEffect(() => {
    setTimeout(() => setVolume(0), 5000)
  }, [])

  // const [game, setGame] = useState<Game>({
  //   gameId: '',
  //   players: [],
  //   words: [],
  //   usedWords: [],
  //   round: 0,
  //   state: GameState.NOT_CONNECTED,
  //   image: null,
  //   roundTime: 0
  // } as Game)

  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const requestedGame = urlParams.get('game')
  // const mode = urlParams.get('mode') // view

  // useEffect(() => {
  //   if (requestedGame && !gameCode) {
  //     setGameCode(requestedGame)
  //     joinGame(requestedGame)
  //   }
  // }, [requestedGame, gameCode])

  // useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log("recovered?", socket.recovered);
  //   })

  //   socket.on('gameCreated', (games: string[]) => {
  //     setGames(games)
  //   })

  //   socket.on("displayImage", (game): any => {
  //     const { image, words } = game;
  //     setGame(game)

  //     console.log(`imageUrl: ${image}`)
  //   });

  //   socket.on("endGuessing", (game: Game) => {
  //     const { players, usedWords } = game;

  //     setGame(game)

  //     setScores(players);
  //     setUsedWords(usedWords)
  //     setGuessTime({ remainingTime: -1, totalTime: -1 })
  //   });

  //   socket.on("newRound", (game: Game) => {
  //     setGame(game)
  //     // setSentence(null);
  //     setSentenceSubmitted(false)
  //     setScores([])
  //     setGuesses([]);
  //     setGuessesSubmitted(false);
  //     setUsedWords([]);
  //     setGuessTime({ remainingTime: -1, totalTime: -1 })
  //   });

  //   socket.on('updatePlayers', (game: Game) => {
  //     const { players } = game
  //     setGame(game)
  //     setPlayerList(players)
  //   })

  //   socket.on('generatingImage', (game) => {
  //     setGame(game)
  //   })

  //   socket.on('guessingTimeRemaining', (remainingTime, totalTime) => {
  //     setGuessTime({ remainingTime, totalTime })
  //   })
  // }, [gameCode]);

  // const joinGame = (gameCode: string) => {
  //   socket.emit("joinGame", gameCode, playerName);
  //   console.log("Joining game", gameCode);
  // };





  return (
    <SocketProvider socket={socket}>
      <PlayerNameProvider>
        <GameProvider>
          <Template>
            <Router>
              <Routes>
                <Route path="/" element={playerName ? <Navigate replace to="/game-selection" /> : <NameEntry />} />
                <Route path="/game-selection" element={<GameSelection />} />
                <Route path="/gameplay/:gameName?" element={<Gameplay />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="*" element={!playerName ? <Navigate replace to="/" /> : <></>} /> {/* Redirects any unknown route to root */}
              </Routes>
            </Router >
          </Template>
        </GameProvider>
      </PlayerNameProvider>
    </SocketProvider>
  );

  // if (!playerName) {
  // return (<div className="bg-emerald-300 font-sans min-h-full flex justify-between flex-col">
  //   <div className="wood_panel__top"></div>
  //   <div className="game__background grow bg-center">
  //     <div className="container mx-auto px-4 py-4 grow">
  //       <form onSubmit={(e) => {
  //         updatePlayerName(tempPlayerName)
  //       }}>
  //         <div className="game__text-container">
  //           <input type="text" className="game__text-input" value={tempPlayerName} onChange={e => setTempPlayerName(e.target.value)} placeholder="Your name" />
  //         </div>
  //       </form>
  //     </div>
  //   </div>
  //   <div className="wood_panel__bottom"></div>
  // </div>)
  // }

  // return (
  //   <div className="bg-emerald-300 font-sans min-h-full flex justify-between flex-col">
  //     <div className="wood_panel__top"></div>
  //     <div className="game__background grow bg-center">
  //       <div className="flex justify-between items-center block px-4">

  //         {/* {players && players.length > 0 && <div>Players: {players?.map((player) => <span>{player.playerName} </span>)}</div>} */}
  //         {game.state === GameState.GUESSING && guessTime.remainingTime >= 0 && <div>{guessTime.remainingTime}/{guessTime.totalTime}</div>}

  //         {!players &&
  //           <>
  //             <div>Welcome {playerName}</div>
  //             <Button onClick={newGame} outline gradientMonochrome="success">Create Game  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  //               <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
  //             </svg>
  //             </Button>
  //           </>
  //         }
  //       </div>
  //       <div className="game__container container mx-auto grow overflow-hidden">
  //         {game.state === GameState.ROUND_OVER &&
  //           <div className="m-10">
  //             <Card>
  //               <div className="mb-4 flex items-center justify-between">
  //                 <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Game Over</h5>
  //                 {/* <div>Correct Answers: {usedWords.join(', ')}</div> */}
  //                 <Button onClick={startNewRound} outline gradientMonochrome="success">Play Again</Button>
  //               </div>
  //               <div className="flow-root">
  //                 <ul className="divide-y divide-gray-200 dark:divide-gray-700">
  //                   <li className="flex font-normal justify-between py-3 sm:py-4">
  //                     {scores && scores.length > 0 && usedWords.map((word, index) => (
  //                       <div key={index}>
  //                         <div className="font-bold">{word}</div>
  //                         {scores.map((player) => { if (!player.guesses.includes(word)) { return <></> } return (<div key={player.playerName}>{player.playerName}</div>) })}
  //                       </div>
  //                     ))}
  //                     {/* {scores.map((player, index) => (
  //                       <div key={index}>
  //                         <h3>{player.playerName}: Score: {player.roundScore} total: {player.score}</h3>
  //                         <p>Guesses: {player.guesses.join(', ')}</p>
  //                         <p>Words: {player.words.join(', ')}</p>
  //                         <hr />
  //                       </div>
  //                     ))} */}
  //                   </li>
  //                 </ul>
  //               </div>
  //             </Card>
  //           </div>
  //         }

  //         {game.state === GameState.ROUND_OVER && (!scores || scores.length === 0) && <div className="m-10">Waiting...</div>}

  //         {!players &&
  //           <div className="m-10">
  //             <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 grid-rows-auto gap-y-2 gap-x-2">
  //               {games && games.length > 0 && games.map((game) => {
  //                 return <div className="bg-yellow-700 grid grid-flow-row-dense grid-cols-1 md:first:col-span-3 grid-rows-auto gap-y-0 rounded-mdborder border-indigo shadow-md overflow-hidden">
  //                   <h2 className="p-2 text-xl capitalize text-green-50 font-bold">{game}</h2>
  //                   <div className="p-2 bg-yellow-900 rounded-md shadow-inner m-2 flex space-x-4 justify-end align-center">
  //                     <div>Players:</div>
  //                     <div>
  //                       <button
  //                         className="bg-dark dark:bg-dark-2 border-dark dark:border-dark-2 rounded-md inline-flex items-center justify-center py-2 px-3 text-center text-base font-medium text-dark bg-green-600 hover:bg-body-color hover:border-body-color disabled:bg-gray-3 disabled:border-gray-3 disabled:text-dark-5 text-white"
  //                         onClick={(e) => {
  //                           setGameCode(game);
  //                           joinGame(game)
  //                         }}>join</button>
  //                     </div>
  //                   </div>
  //                 </div>
  //               })}
  //             </div>
  //           </div>}


  //         {game.state === GameState.LOBBY && !sentenceSubmitted &&
  //           <div className="m-10">
  //             <form onSubmit={submitSentence}>
  //               <Textarea
  //                 className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
  //                 value={sentence ?? ''}
  //                 onChange={(e) => setSentence(e.target.value)}
  //                 placeholder="Enter sentence"
  //                 required
  //                 rows={4}
  //               />
  //               <Button type="submit">Submit</Button>
  //             </form>
  //           </div>}

  //         {game.state === GameState.GENERATING_IMAGE && <div className="flex inset-0 justify-center items-center"><div className="game__loading-background"><div className="loader"></div></div></div>}

  //         {sentenceSubmitted && game.state === GameState.LOBBY &&
  //           <div className="flex inset-0 justify-center items-center">
  //             <div className="game__hanging-panel">
  //               <div>
  //                 Waiting for other players…
  //               </div>
  //             </div>
  //           </div>}

  //         {game.state === GameState.GUESSING && game.image && (
  //           <div className="game__image-container">
  //             {/* <div className="game__image-container--background"></div> */}
  //             <div className="game__image-container--image"><img src={game.image!} /></div>

  //             {!guessesSubmitted && <div className="flex flex-wrap gap-2">
  //               {game.words && game.words.length && game.words.map((word, index) => (
  //                 <Button
  //                   size="md"
  //                   {...(guesses.includes(word) ? { gradientMonochrome: "lime" } : { gradientDuoTone: "cyanToBlue" })}
  //                   key={index}
  //                   onClick={() => {
  //                     const guessedWords = [...(guesses.includes(word) ? guesses.filter((w) => w !== word) : [...guesses, word])].slice(0, 3)
  //                     setGuesses(guessedWords)
  //                     guessWords(guessedWords)
  //                   }}>
  //                   {word}
  //                 </Button>
  //               ))}
  //             </div>}
  //           </div>
  //         )}

  //         {game.state === GameState.GUESSING && guessesSubmitted && (
  //           <div className="game__hanging-panel">
  //             <div>
  //               Waiting for other players…
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     </div>

  //     <div className="wood_panel__bottom"></div>
  //   </div>
  // );
};

export default App;
